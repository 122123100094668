
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
:root{
    
    --dark-one:#333;
    --dark-two:#7a7a7a;
    --light-one:#fff;
    --light-two:#f9fafb;
    --main-color:rgba(242, 116, 86, 1);

}
html body{
    scroll-behavior:smooth;
    transition: 1s;
    font-family: 'Poppins', sans-serif;
}
a{
    text-decoration: none;
}
ul{
    list-style: none;
}
.uni-up-margin{
  margin-top: 5rem;
  overflow-x: hidden;
}


nav {
  width: 100%;
  background-color: rgb(251, 244, 244, 0.85); /* Remove alpha value for full opacity */
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  position: fixed; /* Ensure the navbar remains fixed */
  top: 0;
  left: 0;
  z-index: 1001;
  border-bottom: 1px solid gray;
  box-shadow: 0px 15px 10px -15px #111;
  justify-content: space-around;
  align-items: center;
}

.navbarr{
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
    z-index: 1001;
    top: 0;
    border-bottom: 1px solid gray;
    box-shadow: 0px 15px 10px -15px #111;
    justify-content: space-around;
    align-items: center;
}
.margin{
  height: 0.4rem;
  background-color: var(--light-one);
}
.nav_container{
    position: relative;
    z-index: 5;
    /* max-width: 92rem; */
    /*background-color: red;*/
    padding: 0 4rem;
    /* margin: 0 5rem; */
    
}
nav .nav_container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 5rem;
}
.logo{
width: 300px;
display: flex;
align-items: center;
}
.links ul{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.links ul a{
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;

}
.links a{
    display: inline-block;
    padding: 0.9rem 1.2rem;
    color: var(--dark-one);
    font-size: larger;
    text-transform: uppercase;
    transition: 0.3s;
}
.active_nav{
  background-color: white;
  color: #081340;
}
.links a.active_nav{
    background-color: var(--main-color);
    color: var(--light-one);
    border-radius:2rem ;
    font-size: 1rem;
    padding: 0.9rem 2.1rem;
    margin-left: 1rem;
    
}
.links a.active_nav:hover{
  opacity: 0.8;
  color: var(--light-one);
}

.links a:hover{
    color:#a7a8ad; 
}

.hamburger-menu {
  width: 2.7rem;
  height: 3rem;
  z-index: 100;
  position: relative;
  display: none;
  align-items: center;
  justify-content: flex-end;
}

.hamburger-menu .bar {
  position: relative;
  width: 2.1rem;
  height: 3px;
  border-radius: 3px;
  background-color: var(--dark-one);
  transition: 0.5s;
}

.bar:before,
.bar:after {
  content: "";
  position: absolute;
  width: 2.1rem;
  height: 3px;
  border-radius: 3px;
  background-color: var(--dark-one);
  transition: 0.5s;
}

.bar:before {
  transform: translateY(-9px);
}

.bar:after {
  transform: translateY(9px);
}

nav.open .hamburger-menu .bar {
  background-color: transparent;
  transform: rotate(360deg);
}

nav.open .hamburger-menu .bar:before {
  transform: translateY(0) rotate(45deg);
  background-color: var(--light-one);
}

nav.open .hamburger-menu .bar:after {
  transform: translateY(0) rotate(-45deg);
  background-color: var(--light-one);
}

nav.open .links {
  transform: translateX(0);
}
.nav_item:hover{
  cursor: pointer;
}

.nav_item a{
  position: relative;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  padding: 0 10px;
  letter-spacing: 0.5px;


}
.nav_item a::after{
  content: "";
  position: absolute;
  background-color: #40A2D5;
  height: 3px;
  width: 0;
  left: 0;
  bottom: -10px;
  transition: 0.3s;
}
.nav_item a:hover::after{
  width: 100%;
}

.test1{
    height: 50vh;
    width: 100vw;
    background-color: var(--dark-two);
}
.test2{
    height: 50vh;
    width: 100vw;
    background-color: var(--dark-one);
}

@media (max-width: 1048px){
    .links a {
        font-size: 0.8rem;
        padding: 0.65rem 0.7rem;
      }
    
      .links a.active {
        font-size: 1rem;
        padding: 0.7rem 1.7rem;
        margin-left: 0.6rem;
      }
}

@media (max-width: 830px){

    .navbarr{
      display: unset;
    }
    .hamburger-menu {
        display: flex;
      }
      .nav_container{
        z-index: unset;
      }
    
      
    
      .links {
        position: fixed;
        width: 60%;
        height: 100vh;
        top: 0;
        right: 0;
        background-color: var(--main-color);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        transform: translateX(120%);
        transition: 0.5s;
        border-radius: 0 0 0 50%;
        box-shadow: 0px 0px 75px -6px;
      }
      .img-content{
        width: 12rem;
      }
    
      .links ul {
        flex-direction: column;
      }
    
      .links a {
        color: var(--light-one);
      }
      
    
      .links a.active {
        margin-left: 0;
        margin: 0.5rem 0;
      }
      .links a.active_nav{
        background-color: var(--light-one);
        color:#19223A;
        border-radius:2rem ;
        font-size: 1rem;
        padding: 0.7rem 1.5rem;
        margin-left: 1rem;
        
    }
    .links a.active_nav:hover{
      opacity: 0.8;
      color: #19223A;
    }
}
@media (max-width:430px){
    nav .nav_container{
        margin: 0;
    }
    .logo{
        width: 150px;
    }
}

